import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationFr from '../locales/fr/translations.json';
import translationEn from '../locales/en/translations.json';

i18next.use(initReactI18next).init({
  fallbackLng: 'fr',
  lng: 'fr',
  resources: {
    fr: {
      translations: translationFr,
    },
    en: {
      translations: translationEn,
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
  returnObjects: true,
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
});

i18next.languages = ['fr', 'en'];

export default i18next;
