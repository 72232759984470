export interface State {
  language: string;
}

export enum Actions {
  setLanguage = 'SET_LANGUAGE',
}

interface Action {
  type: Actions;
  payload: Partial<State>;
}

export const initialState = {
  language: 'fr',
};

const languageReducer = (state: State, action: Action): State => {
  const { type, payload } = action;

  switch (type) {
    case Actions.setLanguage:
      // console.log('SET_LANGUAGE', payload);

      return {
        ...state,
        language: payload.language ?? 'fr',
      };

    default:
      throw new Error(`No case for type ${type} found in languageReducer.`);
  }
};

export default languageReducer;
