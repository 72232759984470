import { ReactNode, createContext, useReducer, useContext } from 'react';

import reducer, { Actions, initialState } from './languageReducer';
import { useTranslation } from 'gatsby-plugin-react-i18next';

type ContextProps = {
  children: ReactNode;
};

type ContextValue = {
  language: string;
  setLanguage: (language: string) => void;
};

const Context = createContext<ContextValue | undefined>(undefined);

export function LanguageProvider({ children }: ContextProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { i18n } = useTranslation();

  const setLanguage = (language: string) => {
    i18n.changeLanguage(language);
    dispatch({
      type: Actions.setLanguage,
      payload: {
        language,
      },
    });
  };

  const value = {
    language: state.language,
    setLanguage,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

const useLanguageContext = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(
      'useLanguageContext must be used within a LanguageProvider.',
    );
  }

  return context;
};

export default useLanguageContext;
