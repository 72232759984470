import React from 'react';
import i18next from '../i18n/config';
import { I18nextProvider, withTranslation } from 'react-i18next';
import '../i18n/config';

import { LanguageProvider } from './context/languageContext';

interface Props {
  element: React.ReactNode;
}

const CombinedProvider = ({ element }: Props) => {
  const LgProvider = withTranslation()(LanguageProvider);

  return (
    <I18nextProvider i18n={i18next}>
      <LgProvider>{element}</LgProvider>
    </I18nextProvider>
  );
};

export default CombinedProvider;
